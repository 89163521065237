@font-face {font-family: "Fixedsys";
  src: url("fonts/fixedsys.eot");
  src: url("fonts/fixedsys.eot?#iefix") format("embedded-opentype"),
  url("fonts/fixedsys.woff2") format("woff2"),
  url("fonts/fixedsys.woff") format("woff"),
  url("fonts/fixedsys.ttf") format("truetype");
}

body {
    background-color: #0000af;
    font-family: "Fixedsys", "Courier New", monospace;
    color: white;
}

label {
    margin: 0;
}

header {
    background-color: #af0000;
}

header > section {
    border: 2px solid white;
}

nav {
    padding: 12px 10px;
    background-color: black;
}

nav .button {
    margin-right: 10px;
    padding: 0.3em 1em;
    background-color: #333;
    line-height: 1;
}

nav .button:hover {
    color: gold;
    cursor: pointer;
}

nav .button::before {
    padding-right: 5px;
    content: '<';
    color: #666;
}

nav .button:hover::before {
    color: white;
}

nav .button::after {
    padding-left: 5px;
    content: '>';
    color: #666;
}

nav .button:hover::after {
    color: white;
}

nav .button.active {
    background-color: #af0000;
    color: white;
}

nav .button.active::before {
    color: black;
}

nav .button.active::after {
    color: black;
}

nav input {
    padding: 0 7px;
    background-color: black;
    border: none;
    outline: none;
    color: white;
}

nav input:focus {
    background-color: white;
    color: black;
}

.sidebar {
    background-color: #555;
    border: 1px solid black;
    box-shadow: 10px 10px 0 black;
}

.sidebar:hover {
    background-color: #af0000;
}

.sidebar ul,
.sidebar li {
    margin: 0;
    padding: 0;
    list-style: none;
}

.sidebar-title {
    margin-bottom: 5px;
    border: 1px solid rgba(0,0,0,.2);
}

.sidebar-content {
    border: 1px solid rgba(0,0,0,.2);
    min-height: 200px;
}

.sidebar-content .button {
    display: block;
    padding: 3px 10px;
    text-align: left;
    color: yellow;
}

.sidebar-content .button::selection,
.sidebar-content .button:hover {
    background-color: rgba(0,0,0,.2);
    cursor: pointer;
}

.sidebar-content .button.active {
    background-color: black;
}

article {
    margin: 0 1em;
    margin-top: 25px;
    margin-bottom: 3.5em;
    padding: 0.5em;
    border: 2px solid rgba(0,0,0,.2);
}

article .title {
    position: relative;
    margin-top: -25px;
    margin-left: -20px;
    padding: 5px 15px;
    /* background-color: rgba(0,0,0,.3); */
    background-color: blue;
    /* border: 1px solid rgba(255, 255, 255, .4); */
    box-shadow: 10px 10px 0 black;
}

article:hover .title {
    background-color: #af0000;
}

article h5 {
    text-transform: uppercase;
}

article .content {
    padding-top: 18px;
}

article .content img {
    width: 250px;
    height: 150px;
    object-fit: cover;
}

.footer {
    padding: 1em;
    background-color: blue;
    color: yellow;
}
